import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { updateUI } from "app/features/UIControllerSlice";
function PageLayout({ background, children, customClass }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateUI({ type: "LAYOUT", value: "page" }));
  }, [pathname]);

  return (
    <SuiBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      backgroundColor={background}
      customClass={`overflow-x-hidden ${customClass}`}
    >
      {children}
    </SuiBox>
  );
}

PageLayout.defaultProps = {
  background: "default",
};

PageLayout.propTypes = {
  customClass: PropTypes.string,
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
