import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getLocalStorageData } from "hooks/useLocalStorage";
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    const auth_token = await getLocalStorageData("auth_token");
    if (auth_token) {
      headers.set("authtoken", auth_token);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  return result;
};

export const baseAPIService = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["COMMON_GET"],
  endpoints: () => ({}),
});
