import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SidenavLink from "Container/Sidenav/SidenavLink";
import SidenavCollapse from "Container/Sidenav/SidenavCollapse";

import styles from "Container/Sidenav/styles/sidenav";

import Logo from "assets/images/StaffWay-logo.png";
import { updateUI } from "app/features/UIControllerSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import sidenavData from "./data/sidenavData";

const Sidenav = ({ ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("xl"));
  const { uiControl } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav } = uiControl;
  const classes = styles({ miniSidenav, transparentSidenav });
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const closeSizenav = () => dispatch(updateUI({ type: "miniSidenav", value: true }));
  useEffect(() => {
    dispatch(
      updateUI({
        type: "miniSidenav",
        value: tabView,
      })
    );
  }, [dispatch, location, tabView]);
  const renderRoutes = sidenavData.map(({ id, icon, link, label, sub_menu, type }) => {
    let returnValue;
    if (type === "link") {
      returnValue = (
        <SidenavLink to={link} name={label} icon={icon} active={link === `/${collapseName}`} />
      );
    } else if (type === "collapse") {
      returnValue = <SidenavCollapse name={label} icon={icon} subMenu={sub_menu} />;
    } else if (type === "title") {
      returnValue = (
        <SuiTypography
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          customClass={classes.sidenav_title}
        >
          {label}
        </SuiTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider />;
    }
    return (
      <ListItem component="li" key={`${id}`} sx={{ order: id }}>
        {returnValue}
      </ListItem>
    );
  });

  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSizenav}
        >
          <SuiTypography variant="h6" textColor="secondary">
            <Icon className="font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <Link to="/dashboard">
          <SuiBox
            component="img"
            src={Logo}
            alt="PolEasy Logo"
            customClass={classes.sidenav_logo}
          />
        </Link>
      </SuiBox>
      <Divider />
      <List className={classes.sidenav_list}>{renderRoutes}</List>
    </Drawer>
  );
};

export default Sidenav;
