import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import styles from "Container/LayoutContainers/DashboardLayout/styles";
import { updateUI } from "app/features/UIControllerSlice";

function LayoutContainer({ children }) {
  const dispatch = useDispatch();
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, direction } = controller;
  const { pathname } = useLocation();
  const classes = styles({ miniSidenav, direction });

  useEffect(() => {
    dispatch(updateUI({ type: "LAYOUT", value: "dashboard" }));
  }, [pathname]);

  return <SuiBox customClass={classes.layoutContainer}>{children}</SuiBox>;
}

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContainer;
