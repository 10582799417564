import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import EngineeringTwoToneIcon from "@mui/icons-material/EngineeringTwoTone";
import CustomerSupport from "Container/Icons/CustomerSupport";
import Shop from "Container/Icons/Shop";
import SpaceShip from "Container/Icons/SpaceShip";

export default [
  {
    id: 1,
    type: "link",
    label: "Dashboard",
    link: "/dashboard",
    icon: <Shop size="14px" />,
  },
  {
    id: 2,
    type: "collapse",
    label: "Jobs",
    icon: <WorkTwoToneIcon size="14px" />,
    sub_menu: [
      { id: 2.1, order: 1, label: "Manage Jobs", link: "/jobs" },
      { id: 2.2, order: 2, label: "Applicants", link: "/applicants" },
    ],
  },
  {
    id: 3,
    type: "link",
    label: "Companies",
    link: "/companies",
    icon: <BusinessTwoToneIcon size="14px" />,
  },
  {
    id: 4,
    type: "link",
    label: "Industry",
    link: "/industry",
    icon: <CategoryTwoToneIcon size="14px" />,
  },
  {
    id: 5,
    type: "link",
    label: "Skills",
    link: "/skills",
    icon: <EngineeringTwoToneIcon size="14px" />,
  },
  { id: 6, type: "title", label: "Account Settings" },
  {
    id: 7,
    type: "link",
    label: "Profile",
    link: "/profile",
    icon: <CustomerSupport size="14px" />,
  },
  {
    id: 8,
    type: "link",
    label: "Logout",
    link: "/log-out",
    icon: <SpaceShip size="14px" color="error" />,
  },
];
