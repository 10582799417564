import { useEffect } from "react";

import { FormLabel, Icon } from "@mui/material";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiButton from "components/SuiButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "Validation";
import { useResetPasswordMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const ForgetPassword = () => {
  localStorage.removeItem("timer");
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const { state } = useLocation();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state]);

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      const resetData = { password: values.new_password };
      try {
        const data = await resetPassword({
          data: resetData,
          authtoken: state?.authToken,
        }).unwrap();
        if (data.code && data.statusCode === 200) {
          suiSnackbar(data.message, "success");
          navigate("/");
        }
      } catch (error) {
        if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
          suiSnackbar("Internal server error!");
        } else if (error?.originalStatus === 503) {
          suiSnackbar("Service Unavailable!");
        } else if (error.data?.status) {
          suiSnackbar("Something went wrong!");
        } else {
          suiSnackbar(error.data?.message || "Internet Connection Lost!");
        }
      }
    },
  });

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Reset Password
      </SuiTypography>
      <SuiTypography component="p" variant="formSubHeading">
        Enter New Password
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={2}>
          <FormLabel htmlFor="new_password">New Password</FormLabel>
          <SuiPasswordInput
            index={1}
            attr={{ name: "new_password", placeholder: "New Password" }}
            value={formik.values.new_password}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.new_password}
            error={formik.errors.new_password}
          />
          <SuiBox display="flex" alignItems="flex-start" pt={0.5}>
            <Icon
              fontSize="small"
              sx={{ fontSize: "0.85rem !important", marginTop: "2px", color: "#6E828A" }}
            >
              info_outlined
            </Icon>
            <SuiTypography
              fontWeight="medium"
              textColor="text2"
              sx={{ fontSize: "0.7rem" }}
              px={0.5}
              py={0}
            >
              Note: Password required at least 8 characters, 1 number, 1 Alphabet, 1 special
              character.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="confirm_password">Confirm Password</FormLabel>
          <SuiPasswordInput
            index={2}
            attr={{ name: "confirm_password", placeholder: "Confirm Password" }}
            value={formik.values.confirm_password}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.confirm_password}
            error={formik.errors.confirm_password}
          />
        </SuiBox>
      </SuiBox>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={3}
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty}
          variant="contained"
          buttonColor="info"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {isLoading ? "Changing...." : "Change Password"}
        </SuiButton>
      </SuiBox>
      <SuiBox textAlign="center">
        <SuiButton
          tabIndex={4}
          disabled={formik.isSubmitting}
          component={Link}
          to="/"
          variant="outlined"
          buttonColor="dark"
          fullWidth
        >
          Cancel
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default ForgetPassword;
