import { Box, Button, Container, Grid, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import React from "react";
import { Link } from "react-router-dom";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import backgroundObj from "assets/images/backgroundObj.png";
import img404 from "assets/images/404.png";

const NotFound = () => {
  return (
    <Container maxWidth="xxl" fixed sx={{ height: "80vh" }}>
      <Box
        width={300}
        position="fixed"
        zIndex={-1}
        bottom={0}
        right={0}
        component="img"
        alt="backgroundObj"
        src={backgroundObj}
      />
      <Grid container spacing={2} flexDirection="column" height="100%" justifyContent="center">
        <Grid item xs="auto">
          <Box
            width={250}
            mb={-6}
            component="img"
            ml={{ xs: 0, xl: 8 }}
            sx={{ maxWidth: "100%", height: "auto" }}
            alt="404"
            src={img404}
          />
        </Grid>
        <Grid item xs="auto" alignSelf="center">
          <Typography variant="h1" fontSize={{ xs: 40, md: 80 }} fontWeight={600}>
            Opps! Page Not Found
          </Typography>
          <Typography
            variant="h5"
            fontWeight={500}
            fontSize={{ xs: 18, md: 30 }}
            color="#6E828A"
            pt={1}
            maxWidth={700}
          >
            <span style={{ color: colors.success.main }}>Sorry, </span>
            the page you are looking for is not exist, Please check the URL.
          </Typography>
          <Box component={Link} display="inline-block" to="/dashboard" mt={4}>
            <Button
              variant="contained"
              startIcon={<WestRoundedIcon sx={{ color: colors.success.main }} />}
              size="large"
              sx={{
                textTransform: "capitalize",
                bgcolor: "#005CE6",
                boxShadow: "4px 4px 0 0 #0b409c",
                px: 3,
              }}
            >
              Go Back to Dashboard
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
