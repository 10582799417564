import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightMedium } = typography;
const { borderRadius } = borders;
const { text2, white, info } = colors;

export default {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.sm,
      fontWeight: fontWeightMedium,
      textTransform: "none",
      lineHeight: "inherit",
      backgroundColor: "#E5EBED !important",
      padding: `${pxToRem(6)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      color: `${text2.main} !important`,
      opacity: "1 !important",
      transition: "all 300ms ease",
      "&.Mui-selected": {
        color: `${white.main} !important`,
        backgroundColor: `${info.main} !important`,
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};
