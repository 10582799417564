import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

const { dark, white, grey } = colors;
const { size, fontWeightRegular } = typography;

export default {
  styleOverrides: {
    root: {
      display: "grid !important",
      placeItems: "center !important",
      width: "100% !important",
      height: "auto !important",
      padding: 0,
      fontSize: `${size.sm} !important`,
      fontWeight: `${fontWeightRegular} !important`,
      lineHeight: "1.4 !important",
      color: `${grey[700]} !important`,
      backgroundColor: `${white.main} !important`,
      backgroundClip: "padding-box !important",
      border: "none",
      appearance: "none !important",
      borderRadius: 0,
      transition: "box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important",
      "&::before": {
        borderBottom: "0px solid #D3E0E5 !important",
      },
      "&:hover::before": {
        borderBottom: "2px solid #D3E0E5 !important",
      },
    },

    input: {
      width: "100% !important",
      "&::-webkit-input-placeholder": {
        color: `${dark.main} !important`,
      },
    },
  },
};
