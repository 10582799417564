import { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiOTPInput from "components/SuiOTPInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useForgotPasswordMutation, useVerifyOtpMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
const useStyles = makeStyles(() => ({
  lineHight: {
    lineHeight: "0.75rem !important",
  },
  padding: {
    paddingTop: "0",
  },
  icon: {
    fontSize: "0.85rem !important",
    marginTop: "2px",
  },
}));

const EnterOTP = () => {
  const classes = useStyles();
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [verifyOtp] = useVerifyOtpMutation();
  const [forgotPassword] = useForgotPasswordMutation();
  const [btnTxt, setBtnTxt] = useState({ continue: "Continue", resend: "Resend" });
  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state]);

  const otpHandler = async (enterOtp) => {
    const enteredOTP = enterOtp.join("");
    if (enteredOTP.length === 0) {
      suiSnackbar("Please enter OTP!");
    } else if (enteredOTP.length < 6) {
      suiSnackbar("Please enter valid OTP!");
    } else {
      const body = {
        otp: enteredOTP,
      };
      setBtnTxt({ ...btnTxt, continue: "Verifing OTP..." });
      try {
        const data = await verifyOtp({ data: body, authToken: state?.authToken }).unwrap();
        if (data.code && data.statusCode === 200) {
          navigate("/reset-password", { replace: true, state: { authToken: state?.authToken } });
          suiSnackbar(data?.message, "success");
          setBtnTxt({ ...btnTxt, continue: "Continue" });
        }
      } catch (error) {
        setBtnTxt({ ...btnTxt, continue: "Continue" });
        if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
          suiSnackbar("Internal server error!");
        } else if (error?.originalStatus === 503) {
          suiSnackbar("Service Unavailable!");
        } else if (error.data?.status) {
          suiSnackbar("Something went wrong!");
        } else {
          suiSnackbar(error.data?.message || "Internet Connection Lost!");
        }
      }
    }
  };

  const reSendOTPHandler = async () => {
    setBtnTxt({ ...btnTxt, resend: "Sending OTP..." });
    try {
      const data = await forgotPassword(state).unwrap();
      if (data.code && data.statusCode === 200) {
        localStorage.removeItem("timer");
        suiSnackbar(data?.message, "success");
        localStorage.setItem("timer", JSON.stringify({ minute: 4, seconds: 59 }));
        setBtnTxt({ ...btnTxt, resend: "Resend" });
      }
    } catch (error) {
      localStorage.removeItem("timer");
      setBtnTxt({ ...btnTxt, resend: "Resend" });
      if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
        suiSnackbar("Internal server error!");
      } else if (error?.originalStatus === 503) {
        suiSnackbar("Service Unavailable!");
      } else if (error.data?.status) {
        suiSnackbar(error.data?.message || "Internet Connection Lost!");
      } else {
        suiSnackbar("Something went wrong!");
      }
    }
  };
  const emailAdd = `${state?.email.slice(0, 2).padEnd(6, "*")}${state?.email.slice(6)}`;
  // const emailAdd = `${state?.email}`;
  console.log(state);
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Verification Required
      </SuiTypography>
      <Grid
        className={classes.lineHight}
        columnSpacing={1}
        container
        rowSpacing={1}
        pt={0.5}
        pb={2.5}
        textAlign="left"
      >
        <Grid className={classes.padding} item>
          <SuiTypography variant="formSubHeading" textColor="text2" fontWeight="regular">
            We have sent you an OTP on
          </SuiTypography>
        </Grid>
        <Grid className={classes.padding} item xs="auto">
          <SuiTypography variant="formSubHeading" textColor="text2" fontWeight="regular">
            {emailAdd}
          </SuiTypography>
        </Grid>
      </Grid>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <SuiOTPInput
            initialResandTime={{ minute: 4, seconds: 59 }}
            buttonVariant={{ continue: "contained", reSend: "outlined" }}
            buttonColor={{ continue: "info", reSend: "dark" }}
            buttonTxt={{
              continue: btnTxt.continue,
              reSend: btnTxt.resend,
            }}
            btnFns={{ getOTPFn: otpHandler, reSendOTPFn: reSendOTPHandler }}
          />
        </SuiBox>
      </SuiBox>
    </>
  );
};
export default EnterOTP;
