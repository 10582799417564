import { baseAPIService } from ".";

const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    loginWithPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/login",
          method: "POST",
          body: data,
        };
      },
    }),
    forgotPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/forgotPassword",
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: build.mutation({
      query: ({ data, authToken }) => {
        return {
          url: "admin/verifyOtp",
          method: "POST",
          headers: {
            authtoken: authToken,
          },
          body: data,
        };
      },
    }),
    resetPassword: build.mutation({
      query: ({ data, authtoken }) => {
        return {
          url: "admin/resetPassword",
          method: "PUT",
          headers: {
            authtoken,
          },
          body: data,
        };
      },
    }),
    // logout: build.mutation({
    //   query: () => {
    //     return {
    //       url: "v1/adminLogout",
    //       method: "POST",
    //       body: null,
    //     };
    //   },
    // }),
  }),
  overrideExisting: true,
});
export const {
  useLoginWithPasswordMutation,
  useForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  // useLogoutMutation,
} = AuthApi;
