import { getLocalStorageData } from "hooks/useLocalStorage";
import axios from "axios";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import AWS from "aws-sdk";

const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name, image, size, font) => {
  return {
    sx: {
      bgcolor: image ? "transparent" : `${stringToColor(name)}44`,
      color: stringToColor(name),
      width: `${size || "40px"}`,
      height: `${size || "40px"}`,
      fontSize: `${font || "17px"}`,
    },
    children: `${name?.split(" ")[0][0].toUpperCase()}${name
      ?.split(" ")
      [name?.split(" ").length > 1 ? 1 : 0][0].toUpperCase()}`,
  };
};

export const capitalize = (word) => {
  return word
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    })
    ?.join(" ");
};

export const imageBase = (image) => {
  if (!image) {
    return "";
  } else if (image.indexOf("base64,") === -1) {
    return `data:image/jpeg;base64,${image}`;
  } else {
    return image;
  }
};

export const setDigits = (num) => {
  if (num) {
    return num <= 9 ? `0${num}` : num;
  } else {
    return "--";
  }
};

export const getNewObjectProperties = (prevObj, newObj) => {
  const results = prevObj.filter((obj) => {
    return !newObj.some((obj2) => {
      return JSON.stringify(obj) === JSON.stringify(obj2);
    });
  });
  return results;
};

export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (prevObj[key] !== newObj[key]) {
      if (
        newObj[key]?.length > 0 ||
        !isNaN(newObj[key]) ||
        (newObj[key] && typeof newObj[key] === "object")
      ) {
        resultObject[key] = newObj[key];
      } else {
        resultObject[key] = null;
      }
    }
  }
  if (resultObject["mobile_no"]) {
    resultObject["country_code"] = newObj.country_code;
  }
  return resultObject;
};

export const checkIfEmpty = (value) => {
  if (value && value.length > 0) {
    return value;
  }
  return null;
};

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export const getSearchQueryParams = () => {
  const [searchParams] = useSearchParams();
  let allSearchParams = {};
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    allSearchParams[param] = isNaN(+value) ? value : +value;
  }
  return allSearchParams;
};

export const removeTags = (str) => {
  console.log(str);
  if (str === null || !str) return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "").trim();
};

export const setFeildBySearchParams = (formik, getSearchParams) => {
  for (const key in getSearchParams) {
    const value = getSearchParams[key];
    formik.setFieldValue(key, isNaN(+value) ? value : +value);
  }
};
export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const bgColor = (name) => {
  let hash = 0;
  let i;
  let color = "#";
  for (i = 0; i < name?.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0x77;
    color += `f0${value.toString(16)}`.slice(-2);
  }
  const style = {
    p: "10px",
    objectFit: "contain",
    bgcolor: `${color}55`,
    borderRadius: "7px 7px 0 0",
    m: 0,
  };
  return style;
};

export const appendZero = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const getReports = async (params, url) => {
  const auth_token = await getLocalStorageData("auth_token");
  const config = {
    headers: {
      auth_token: auth_token,
    },
    params,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, config);
  return response;
};

export const generateData = (obj, valueKey) => {
  if (valueKey === "date") {
    return obj?.map((item) => {
      const isNotNum = item[valueKey]?.split("-").every((i) => isNaN(+i));
      if (isNotNum) {
        return item[valueKey];
      } else {
        return moment(item[valueKey]).format("Do MMM YYYY");
      }
    });
  }
  return obj?.map((item) => item[valueKey]);
};

export const uploadFileToS3 = (file, type) => {
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const fileName = type + moment(new Date()).format("DDDD_MM_YYYY_hh_mm_a_x") + file.name;
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: fileName.toLowerCase(),
    Body: file,
    ContentType: file.type,
    ACL: "public-read",
  };
  return [params, s3];
};

export const displayLabel = (apiArray, displayArray) => {
  const selectedJobTypeIds = apiArray.split(",").map(Number);

  const selectedJobTypes = displayArray
    .filter((jobType) => selectedJobTypeIds.includes(jobType.id))
    .map((jobType) => jobType.name);

  return selectedJobTypes.join(", ");
};

export const setCanvasPreview = (
  image, // HTMLImageElement
  canvas, // HTMLCanvasElement
  crop // PixelCrop
) => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }

  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";
  ctx.save();

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  // Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

export function base64ToFile(base64String, filename, mimeType) {
  // Split the base64 string to get the data part
  const base64Data = base64String.split(";base64,").pop();

  // Convert the base64 data to a Uint8Array
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const uint8Array = new Uint8Array(byteNumbers);

  // Create a Blob from the Uint8Array
  const blob = new Blob([uint8Array], { type: mimeType });

  // Create a File object from the Blob
  const file = new File([blob], filename, { type: mimeType });

  return file;
}
