import { useCallback } from "react";

import { FormLabel } from "@mui/material";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useForgotPasswordMutation } from "app/features/api/AuthApi";
import { emailValidationSchema } from "Validation";
import SuiInput from "components/SuiInput";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const EnterEmail = () => {
  localStorage.removeItem("timer");
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation();
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: emailValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
      };
      try {
        const data = await forgotPassword(body).unwrap();
        if (data.code && data.statusCode === 200) {
          const { authToken } = data.data;
          console.log("rrr", data);
          navigate("/verify-otp", { replace: true, state: { authToken, email: body.email } });
          localStorage.removeItem("timer");
          localStorage.setItem("timer", JSON.stringify({ minute: 0, seconds: 5 }));
          suiSnackbar(data?.message, "success");
        }
      } catch (error) {
        if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
          suiSnackbar("Internal server error!");
        } else if (error?.originalStatus === 503) {
          suiSnackbar("Service Unavailable!");
        } else if (error.data?.status) {
          suiSnackbar(error.data?.message || "Internet Connection Lost!");
        } else {
          suiSnackbar(error.data?.message || "Internet Connection Lost!");
        }
      }
    },
  });

  const handleCancel = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Forgot password
      </SuiTypography>
      <SuiTypography component="p" variant="formSubHeading">
        Enter registered email to get OTP
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="email"
            placeholder="Enter Email Address"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
        </SuiBox>
      </SuiBox>
      <SuiBox mt={2} mb={2}>
        <h6
          style={{
            fontSize: ".9em",
            color: "#FC3C3C",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {formik.errors.mobile_no}
        </h6>
      </SuiBox>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={2}
          type="submit"
          variant="contained"
          buttonColor="info"
          disabled={formik.isSubmitting || !formik.dirty}
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? "Sending..." : "Send OTP"}
        </SuiButton>
      </SuiBox>

      <SuiBox>
        <SuiButton variant="outlined" buttonColor="dark" fullWidth onClick={handleCancel}>
          Cancel
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default EnterEmail;
