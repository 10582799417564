import colors from "assets/theme/base/colors";

import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { transparent, gradients, success, info, warning } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundPosition: "center",
      backgroundSize: "contain",
      width: pxToRem(20),
      height: pxToRem(20),
      marginRight: pxToRem(6),
      padding: 0,
      color: transparent.main,
      border: "none",
      outline: `2px solid ${gradients.dark.main}`,
      borderRadius: pxToRem(5.6),
      transition: "all 250ms ease",

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "& .MuiSvgIcon-root": {
        fill: transparent.main,
        width: "auto",
        height: "auto",
      },

      "&.Mui-focusVisible": {
        outline: `2px solid ${transparent.main} !important`,
      },
    },

    colorPrimary: {
      backgroundColor: transparent.main,
      outline: `2px solid ${success.main}`,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          success.main,
          success.main
        )}`,
        outlineColor: success.main,
      },
      "&.MuiCheckbox-indeterminate": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"), ${linearGradient(
          success.main,
          success.main
        )}`,
        outlineColor: success.main,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
    },

    colorSecondary: {
      backgroundColor: transparent.main,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          gradients.dark.main,
          gradients.dark.state
        )}`,
        outlineColor: gradients.dark.main,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
    },

    colorInfo: {
      backgroundColor: transparent.main,
      outline: `2px solid ${info.main}`,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          info.main,
          info.main
        )}`,
        outlineColor: info.main,
      },
      "&.MuiCheckbox-indeterminate": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"), ${linearGradient(
          info.main,
          info.main
        )}`,
        outlineColor: info.main,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
    },

    colorWarning: {
      backgroundColor: transparent.main,
      outline: `2px solid ${warning.main}`,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          warning.main,
          warning.main
        )}`,
        outlineColor: warning.main,
      },
      "&.MuiCheckbox-indeterminate": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"), ${linearGradient(
          warning.main,
          warning.main
        )}`,
        outlineColor: warning.main,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
    },

    colorSuccess: {
      backgroundColor: transparent.main,
      outline: `2px solid ${success.main}`,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          success.main,
          success.main
        )}`,
        outlineColor: success.main,
      },
      "&.MuiCheckbox-indeterminate": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"), ${linearGradient(
          success.main,
          success.main
        )}`,
        outlineColor: success.main,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
    },
  },
};
