export const useAsyncLocalStorage = {
  setItem: (key, value) => Promise.resolve().then(() => localStorage.setItem(key, value)),
  getItem: (key) => Promise.resolve().then(() => localStorage.getItem(key)),
  remoteItem: (key) => Promise.resolve().then(() => localStorage.removeItem(key)),
};

export const getLocalStorageData = async (key) => {
  return await new Promise((resolve) => {
    resolve(localStorage.getItem(key));
  });
};

export const setLocalStorageData = async (key, value) => {
  return await new Promise((resolve) => {
    resolve(localStorage.setItem(key, value));
  });
};
