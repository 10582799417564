import React, { Suspense } from "react";
import DashboardLayout from "Container/LayoutContainers/DashboardLayout";
import DashboardNavbar from "Container/DashboardNavbar";
import Footer from "Container/Footer";
import { Outlet, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import theme from "assets/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Sidenav from "Container/Sidenav";
import { useSelector } from "react-redux";
import SuiBox from "components/SuiBox";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const RootLayout = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const loader = (
    <SuiBox display="flex" height="500px" alignItems="center" justifyContent="center">
      <CommonSpinner size="100px" color={colors.info.main} />
    </SuiBox>
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated ? (
          <>
            <Sidenav />
            <DashboardLayout>
              <DashboardNavbar />
              <Suspense fallback={loader}>
                <Outlet />
              </Suspense>
              <Footer />
            </DashboardLayout>
          </>
        ) : (
          <Navigate to={"/"} />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default RootLayout;
