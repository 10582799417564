import Fade from "@mui/material/Fade";

import typography from "assets/theme/base/typography";

import pxToRem from "assets/theme/functions/pxToRem";

const { fontWeightRegular } = typography;

export default {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: "#f4f4f4",
      color: "#303841",
      fontWeight: fontWeightRegular,
      boxShadow: "0px 4px 12px #CFDADECC",
    },

    arrow: {
      color: "#f4f4f4",
    },
  },
};
