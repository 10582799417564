import { forwardRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SuiBox from "components/SuiBox";
import styles from "components/SuiInput/styles";
import { TextField } from "@mui/material";

const SuiInput = forwardRef(
  (
    { size, withIcon, inputSettungs, error, success, customClass, disabled, inputProps, ...rest },
    ref
  ) => {
    let template;
    const iconBaseClass = !withIcon.baseClass ? "" : `-${withIcon.baseClass}`;
    const iconColor = !withIcon.color ? "dark" : withIcon.color;
    const direction = useSelector((store) => store.uiControl.direction);
    const classes = styles({ size, error, success, withIcon, direction, disabled });
    if (withIcon.icon && withIcon.direction === "left") {
      template = (
        <SuiBox className={clsx(classes.suiInputIcon, customClass)}>
          <IconButton
            type="button"
            onClick={withIcon.onClickIcon}
            className={classes.suiInputIcon_right}
          >
            <Icon
              color={iconColor}
              className={classes.suiInputIcon_icon}
              baseClassName={`material-icons${iconBaseClass}`}
              fontSize="small"
            >
              {withIcon.icon}
            </Icon>
          </IconButton>
          <TextField
            {...rest}
            autoComplete={inputSettungs.autoComplete}
            required={inputSettungs.required}
            inputProps={{ ...inputProps, ref: ref }}
            className={clsx(classes.suiInput, classes.suiInputIcon_input, {
              [classes.suiInput_error]: error,
              [classes.suiInput_success]: success,
              [classes[`suiInput_${size}`]]: size,
            })}
            classes={{
              focused: classes.suiInput_focused,
              disabled: classes.suiInput_disabled,
              error: classes.suiInput_error,
              multiline: classes.suiInput_multiline,
            }}
          />
        </SuiBox>
      );
    } else if (withIcon.icon && withIcon.direction === "right") {
      template = (
        <SuiBox className={clsx(classes.suiInputIcon, customClass)}>
          <TextField
            {...rest}
            autoComplete={inputSettungs.autoComplete}
            required={inputSettungs.required}
            inputProps={{ ...inputProps, ref: ref }}
            className={clsx(classes.suiInput, classes.suiInputIcon_input, {
              [classes.suiInput_error]: error,
              [classes.suiInput_success]: success,
              [classes[`suiInput_${size}`]]: size,
            })}
            classes={{
              focused: classes.suiInput_focused,
              disabled: classes.suiInput_disabled,
              error: classes.suiInput_error,
              multiline: classes.suiInput_multiline,
            }}
          />
          <IconButton
            type="button"
            onClick={withIcon.onClickIcon}
            className={classes.suiInputIcon_right}
          >
            <Icon
              color={iconColor}
              className={classes.suiInputIcon_icon}
              baseClassName={`material-icons${iconBaseClass}`}
              fontSize="small"
            >
              {withIcon.icon}
            </Icon>
          </IconButton>
        </SuiBox>
      );
    } else {
      template = (
        <TextField
          {...rest}
          autoComplete={inputSettungs.autoComplete}
          required={inputSettungs.required}
          inputProps={{ ...inputProps, ref: ref }}
          className={clsx(classes.suiInput, customClass, {
            [classes.suiInput_error]: error,
            [classes.suiInput_success]: success,
            [classes[`suiInput_${size}`]]: size,
          })}
          classes={{
            focused: classes.suiInput_focused,
            disabled: classes.suiInput_disabled,
            error: classes.suiInput_error,
            multiline: classes.suiInput_multiline,
          }}
        />
      );
    }

    return template;
  }
);

SuiInput.defaultProps = {
  size: "medium",
  withIcon: {
    icon: false,
    direction: "none",
    onClickIcon: (e) => e.preventDefault(),
  },
  inputSettungs: {
    autoComplete: "on",
    required: false,
  },
  error: false,
  success: false,
  customClass: "",
  disabled: false,
};

SuiInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  withIcon: PropTypes.shape({
    baseClass: PropTypes.oneOf(["outlined", "rounded", "two-tone", "sharp"]),
    color: PropTypes.oneOf([
      "white",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
    onClickIcon: PropTypes.func,
  }),
  inputSettungs: PropTypes.shape({
    autoComplete: PropTypes.string,
    required: PropTypes.bool,
  }),
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SuiInput;
