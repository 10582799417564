import { removeTags } from "helper";
import moment from "moment";
import * as yup from "yup";
const SUPPOERTED_IMAGE = ["image/jpeg", "image/jpg", "image/png"];
const numericOnly = /^\d+$/;
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const emailPattern = /(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;
const passwordPattern =
  /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g;
const minCr = 3;

const imageWidthAndHeight = (provideFile) => {
  const imgDimensions = { width: null, height: null };

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(provideFile);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result;

      img.onload = function () {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;

        resolve(imgDimensions);
      };
    };
  });
};
export const imageDimensionCheck = yup.addMethod(
  yup.mixed,
  "imageDimensionCheck",
  function (message, requiredWidth, requiredHeight, targetRatio, isDimension) {
    return this.test("image-width-height-check", message, async function (value) {
      const { path, createError } = this;
      if (!value) {
        return true;
      }
      if (typeof value !== "object" && value.split("/")[0] === "https:") {
        return true;
      }
      if (!SUPPOERTED_IMAGE.includes(value.type)) {
        return createError({
          path,
          message,
        });
      }
      if (value.size >= 1048576 * 10) {
        return createError({
          path,
          message: "Max allowed size is less then 10MB",
        });
      }
      const imgDimensions = await imageWidthAndHeight(value);
      if (
        imgDimensions.width <= requiredWidth &&
        imgDimensions.height <= requiredHeight &&
        isDimension
      ) {
        return createError({
          path,
          message: `The image dimension should be ${requiredWidth}px to ${requiredHeight}px!`,
        });
      }

      return true;
    });
  }
);

const password = {
  new_password: yup
    .string()
    .required("You must enter a password.")
    .min(8, "Your password must have at least 8 characters.")
    .matches(passwordPattern, "You have entered an invalid password format."),
  confirm_password: yup
    .string()
    .required("You must confirm your password.")
    .oneOf([yup.ref("new_password"), null], "Confirm password must be match."),
};

export const loginWithPasswordSchema = yup.object({
  email: yup.string().matches(emailPattern, "Please enter valid email address."),
  password: yup
    .string()
    .required("You must enter a password.")
    .min(8, "Your password must have at least 8 characters.")
    .matches(passwordPattern, "You have entered an invalid password format."),
});

export const emailValidationSchema = yup.object({
  email: yup.string().matches(emailPattern, "Please enter valid email address."),
});

export const forgotPasswordSchema = yup.object({
  ...password,
});

export const passwordChangeSchema = yup.object({
  old_password: yup
    .string()
    .required("You must enter a password.")
    .min(8, "Your password must have at least 8 characters.")
    .matches(passwordPattern, "You have entered an invalid password format."),
  ...password,
});

export const companySchema = yup.object({
  company_name: yup
    .string()
    .required("Please enter company name.")
    .min(minCr, `Company name must have at least ${minCr} characters.`)
    .max(60, "Company name may not be longer then 60 characters.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  company_logo: yup.string().required("Please upload a company logo."),
  url: yup.mixed().when("company_logo", {
    is: (image) => image?.split("/")[0] !== "https:",
    then: yup
      .mixed()
      .imageDimensionCheck(
        "Please upload company logo in jpg, jpeg or png format.",
        120,
        120,
        1,
        true
      ),
    otherwise: yup.mixed().notRequired(),
  }),
  company_industry_type: yup.string().required("Please select an industry type."),
  company_address: yup
    .string()
    .min(minCr, `Company address must have at least ${minCr} characters.`)
    .max(250, "Company address may not be longer then 250 characters.")
    .nullable(),
});

export const skillSchema = yup.object({
  skill_name: yup
    .string()
    .required("Please enter skill name.")
    .min(minCr, `Skill name must have at least ${minCr} characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});

export const industrySchema = yup.object({
  industry_name: yup
    .string()
    .required("Please enter industry name.")
    .min(minCr, `Industry name must have at least ${minCr} characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});

export const jobSchema = yup.object({
  job_designation: yup
    .string()
    .required("Please enter designation.")
    .min(minCr, `Designation name must have at least ${minCr} characters.`)
    .max(60, `Designation name must have at least 60 characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  company_id: yup.string().required("Please select a company."),
  skills: yup
    .array()
    .min(2, "Please add at least two skill.")
    .required("Please select add skills."),
  job_summary: yup
    .string()
    .required("Please enter Summary.")
    .min(minCr, `Summary name must have at least ${minCr} characters.`)
    .max(150, `Summary must be within 150 Characters`),
  job_description: yup
    .string()
    .required("Please enter job description.")
    .test("contentRequired", "Please enter job description.", (item) => {
      return removeTags(item);
    }),
  extra_benefits: yup.string().nullable(),
  currency: yup.string().required("Please select currency type."),
  salary_offered: yup
    .string()
    .required("Please enter offered salary.")
    .matches(numericOnly, "Must be numeric only."),
  job_type: yup.string().required("Please select a job type."),
  job_mode: yup.string().required("Please select a mode type."),
  experience: yup
    .string()
    .required("Please enter experience.")
    .matches(numericOnly, "Must be numeric only."),
});

export const DateValidateSchema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), "Start date should not be greater than today.")
    .typeError("Please enter valid start date.")
    .nullable(),
  end_date: yup
    .date()
    .max(new Date(), "End date should not be greater than today.")
    .when("start_date", {
      is: (start_date) => moment.isDate(start_date),
      then: yup
        .date()
        .required("Please enter end date.")
        .typeError("Please enter valid end date.")
        .nullable(),
    })
    .test("lessthenSD", "End date should not be less than start date.", (value, testContext) => {
      return !moment(value).isBefore(testContext.parent.start_date);
    })
    .typeError("Please enter valid end date.")
    .nullable(),
});
