import { createSlice } from "@reduxjs/toolkit";
// import { getSearchQueryParams } from "helper";
// const getSearchParams = () => getSearchQueryParams();
const initialState = {
  isAuthenticated: localStorage.getItem("auth_token"),
  auth_token: localStorage.getItem("auth_token"),
  searchParams: {},
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.auth_token = action.payload.auth_token;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    getSorting: (state, action) => {
      const data = action.payload;
      state.searchParams = { ...state.searchParams, ordering: `${data.order}${data.orderBy}` };
    },
    setSearchParams: (state, action) => {
      const data = action.payload;
      state.searchParams = data;
    },
  },
});

export const { setCredentials, getSorting, setSearchParams } = authSlice.actions;

export default authSlice.reducer;
