import { useSnackbar } from "notistack";

const useSuiSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  // variant could be success, error, warning, info, or default
  const snackbarFn = (message, variant = "error") => enqueueSnackbar(message, { variant });
  return snackbarFn;
};

export default useSuiSnackbar;
