import { lazy } from "react";
import RootLayout from "layouts/RootLayout";
import RootOutlet from "layouts/RootLayout/RootOutlet";

// import Dashboard from "layouts/dashboard";

const SkillsTable = lazy(() => import("layouts/skills/SkillsTable"));
const CommonSkillModules = lazy(() => import("layouts/skills/CommonSkillModules"));

const IndustryTable = lazy(() => import("layouts/industry/IndustryTable"));
const CommonIndustryModules = lazy(() => import("layouts/industry/CommonIndustryModules"));

const CompaniesTable = lazy(() => import("layouts/companies/CompaniesTable"));
const CommonCompaniesModules = lazy(() => import("layouts/companies/CommonCompaniesModules"));

const JobsTable = lazy(() => import("layouts/jobs/JobsTable"));
const CommonJobModules = lazy(() => import("layouts/jobs/CommonJobModules"));

const ApplicantsTable = lazy(() => import("layouts/applicants/ApplicantsTable"));
// const CommonApplicantsModules = lazy(() => import("layouts/applicants/CommonApplicantsModules"));

// const CategoriesTable = lazy(() => import("layouts/product-categories/CategoriesTable"));
// const CommonCategoryModules = lazy(() =>
//   import("layouts/product-categories/CommonCategoryModules")
// );

// const SubCategoriesTable = lazy(() => import("layouts/product-sub-categories/SubCategoriesTable"));
// const CommonSubCategoryModules = lazy(() =>
//   import("layouts/product-sub-categories/CommonSubCategoryModules")
// );

// const PagesTable = lazy(() => import("layouts/pages/PagesTable"));
// const CommonPageUI = lazy(() => import("layouts/pages/CommonPageUI"));

const Profile = lazy(() => import("layouts/profile"));
const ProfileDetails = lazy(() => import("layouts/profile/components/ProfileDetails"));
const ChangePassword = lazy(() => import("layouts/profile/components/ChangePassword"));

import LogIn from "layouts/authentication/log-in";
import LoginWithPass from "layouts/authentication/log-in/Forms/LoginWithPass";
import EnterEmail from "layouts/authentication/log-in/Forms/EnterEmail";
import EnterOTP from "layouts/authentication/log-in/Forms/EnterOTP";
import ForgetPassword from "layouts/authentication/log-in/Forms/ForgetPassword";
const LogOut = lazy(() => import("layouts/authentication/log-out"));

import NotFound from "layouts/404";

const routes = [
  {
    path: "",
    element: <RootLayout />,
    errorElement: <NotFound />,
    protected: true,
    children: [
      {
        path: "/dashboard",
        element: "Dashboard",
        // element: <Dashboard />,
        index: true,
      },
      {
        path: "/jobs",
        element: <RootOutlet />,
        children: [
          {
            path: "",
            index: true,
            element: <JobsTable />,
          },
          {
            path: "add-job",
            element: <CommonJobModules />,
          },
          {
            path: "edit-job/:id",
            element: <CommonJobModules />,
          },
        ],
      },
      {
        path: "/applicants",
        element: <RootOutlet />,
        children: [
          {
            path: "",
            index: true,
            element: <ApplicantsTable />,
          },
        ],
      },
      {
        path: "/companies",
        element: <RootOutlet />,
        children: [
          {
            path: "",
            index: true,
            element: <CompaniesTable />,
          },
          {
            path: "add-company",
            element: <CommonCompaniesModules />,
          },
          {
            path: "edit-company/:id",
            element: <CommonCompaniesModules />,
          },
        ],
      },
      {
        path: "/industry",
        element: <RootOutlet />,
        children: [
          {
            path: "",
            index: true,
            element: <IndustryTable />,
          },
          {
            path: "add-industry",
            element: <CommonIndustryModules />,
          },
          {
            path: "edit-industry/:id",
            element: <CommonIndustryModules />,
          },
        ],
      },
      {
        path: "/skills",
        element: <RootOutlet />,
        children: [
          {
            path: "",
            index: true,
            element: <SkillsTable />,
          },
          {
            path: "add-skill",
            element: <CommonSkillModules />,
          },
          {
            path: "edit-skill/:id",
            element: <CommonSkillModules />,
          },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
        children: [
          {
            element: <ProfileDetails />,
            index: true,
          },
          {
            element: <ChangePassword />,
            path: "change-password",
          },
        ],
      },
      {
        path: "/log-out",
        element: <LogOut />,
      },
    ],
  },
  {
    path: "/",
    element: <LogIn />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <LoginWithPass />,
      },
      {
        element: <EnterEmail />,
        path: "forgot-password",
      },
      {
        element: <EnterOTP />,
        path: "verify-otp",
      },
      {
        element: <ForgetPassword />,
        path: "reset-password",
      },
    ],
  },
];

export default routes;
