export default {
  styleOverrides: {
    root: {
      fontSize: "1rem",
      color: "#ED952F",
      "& .MuiSvgIcon-root": {
        fill: "#ED952F",
      },
    },
    sizeSmall: {
      fontSize: "0.85rem",
    },

    sizeMedium: {
      fontSize: "1rem",
    },

    sizeLarge: {
      fontSize: "2.2rem",
    },
  },
};
