import { useState, useEffect } from "react";
import { FormLabel } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useLoginWithPasswordMutation } from "app/features/api/AuthApi";
import { loginWithPasswordSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiPasswordInput from "components/SuiPasswordInput";
import { setCredentials } from "app/features/AuthSlice";
import { useDispatch } from "react-redux";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const LoginWithPass = () => {
  localStorage.removeItem("timer");
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithPassword, { isLoading }] = useLoginWithPasswordMutation();
  const [btnTxt, setBtnTxt] = useState("Login");
  useEffect(() => {
    if (isLoading) {
      setBtnTxt("Login...");
    } else {
      setBtnTxt("Login");
    }
  }, [isLoading]);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginWithPasswordSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const loginData = await loginWithPassword(values).unwrap();
        if (loginData.code && loginData.statusCode === 200) {
          localStorage.setItem("auth_token", loginData.data.auth_token);
          localStorage.setItem("loggedInTime", new Date());
          dispatch(
            setCredentials({ auth_token: loginData.data.auth_token, isAuthenticated: true })
          );
          navigate("/dashboard");
          suiSnackbar(loginData?.message, "success");
        }
      } catch (error) {
        if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
          suiSnackbar("Internal server error!");
        } else if (error?.originalStatus === 503) {
          suiSnackbar("Service Unavailable!");
        } else if (error.data?.status) {
          suiSnackbar("Something went wrong!");
        } else {
          suiSnackbar(error.data?.message || "Internet Connection Lost!");
        }
      }
    },
  });
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Login
      </SuiTypography>
      <SuiTypography component="p" textColor="text2" variant="formSubHeading">
        Filll up below details to login
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="email"
            placeholder="Enter Email Address"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
        </SuiBox>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <SuiPasswordInput value={formik.values.password} getValue={formik.handleChange} />
          <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <SuiTypography
            customClass="cursor-pointer"
            ml="auto"
            component={Link}
            to="/forgot-password"
            variant="caption"
            textColor="info"
            fontWeight="medium"
            tabIndex="3"
          >
            Forgot Password?
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2} mb={0.5}>
        <SuiButton
          tabIndex={4}
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty}
          variant="contained"
          buttonColor="info"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {btnTxt}
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default LoginWithPass;
